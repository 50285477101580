import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Pricing from "../../components/pricing";
import VrIllustration from "../../images/vr-illustration.png";
import IllustrationVideo from "../../images/mini-video-restaurant.jpg";
import IllustrationMattertag from "../../images/ocomptoir-mattertag.jpg";
import Illustration3D from "../../images/dollhouse-matterport.jpg";
import IllustrationPlanEtage from "../../images/plan-etage.jpg";

function VisitePage() {
  return (
    <Layout>
      <SEO title="Visites virtuelles 3D Matterport - Restaurants et commerces " />
      <div className="mx-auto max-w-screen-xl">
        <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
          <h1>
            Visite virtuelle 3D Matterport pour les resturants et commerces
          </h1>

          <div className="relative mt-4 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h2 className="mt-2 text-gray-900 text-xl leading-8 font-extrabold tracking-tight">
                Comment augmenter la fréquentation de votre établissement ?
              </h2>
              <p className="mt-3 text-lg leading-7">
                Nous utilisons la technologie <strong>Matterport®</strong>, une
                solution tout-en-un qui génère en seulement <strong>24h</strong>{" "}
                la meilleure visite <strong>virtuelle 3D</strong> du marché
                compatible
                <strong>Google Streetview®</strong>, des{" "}
                <strong>photographies HD</strong> pour alimenter votre site et
                vos réseaux ainsi que des vidéos pour vous démarquer.
              </p>
              <h3 className="mt-3 text-lg uppercase leading-6 font-medium text-red-600">
                Démarquez vous de la concurrence
              </h3>
              <p className="mt-3 text-lg leading-7">
                Grâce à notre solution de visites virtuelles immersives, faites
                connaître <strong>votre établissement</strong> à plus de monde
                en permettant à vos clients de le visiter{" "}
                <strong>
                  depuis n’importe quel ordinateur, tablette ou smartphone
                </strong>{" "}
                via un simple lien ou intégré à votre site web.
              </p>
              <h3 className="mt-3 text-lg uppercase leading-6 font-medium text-red-600">
                Répondez à l'attente de vos clients
              </h3>
              <p className="mt-3 text-lg leading-7">
                La grande majorité des consommateurs consultent leur smartphone
                avant de se rendre dans un établissement, c’est devenu un
                réflexe.{" "}
                <strong>Améliorez votre référencement sur Google</strong> et
                votre <strong>visibilité</strong> sur{" "}
                <strong>Google Maps</strong>. La{" "}
                <strong>visite virtuelle 3D</strong> est le moyen le plus
                efficace pour séduire vos futurs clients et leur donner envie de
                se déplacer. Vous gagnez leur confiance en montrant en toute
                <strong>transparence</strong> vos espaces.
              </p>
            </div>
            <div className="mt-10 -mx-4 relative lg:mt-0 lg:row-start-1 lg:row-end-2">
              <img
                className="relative mx-auto"
                src={VrIllustration}
                alt="Visites virtuelles 3D Matterport"
              />
            </div>
          </div>

          <div className="py-12">
            <div className="lg:text-center">
              <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900">
                Démarquez vous de la concurrence avec une solution Tout-en-un
              </h2>
            </div>

            <div className="mt-10">
              <ul className="md:grid md:grid-cols-2 lg:grid-cols-4 md:col-gap-8 row-gap-10">
                <li>
                  <div className="flex flex-col">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center">
                        <img
                          className="relative mx-auto"
                          src={Illustration3D}
                          alt="Plan 3D Matterport"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Plan 3D
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Vue d’ensemble pour visualiser toutes les perspectives
                        du bien, faites la tourner sous tous les angles.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10 md:mt-0">
                  <div className="flex flex-col">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center">
                        <img
                          className="relative mx-auto"
                          src={IllustrationPlanEtage}
                          alt="Vue plan d'étage Matterport"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Vue de plan d'étage
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Découvrez la totalité du bien vue de haut étage par
                        étage avec possibilité d’annotations.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10 md:mt-0">
                  <div className="flex flex-col">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center">
                        <img
                          className="relative mx-auto"
                          src={IllustrationMattertag}
                          alt="Points d'intêret Mattertag®"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Mattertag®
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Points d’intérêts dans la visite virtuelle avec
                        insertion de liens, vidéos, textes, photos.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10 md:mt-0">
                  <div className="flex flex-col">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center">
                        <img
                          className="relative mx-auto"
                          src={IllustrationVideo}
                          alt="Mini vidéo"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Mini vidéo
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Intégrez de courtes vidéos à vos publications sur les
                        réseaux sociaux.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center mt-10 mb-12">
            <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
              Exemple de réalisation{" "}
              <Link
                to="/realisations"
                title="Nos réalisations de visites virtuelles 3D"
                className="text-base text-sm font-semibold underline"
              >
                (Voir toutes nos réalisations)
              </Link>
            </h2>
            <div className="iframe-container">
              <iframe src="https://my.matterport.com/show/?m=wvCrcRwDCKe"></iframe>
            </div>
          </div>
        </div>
      </div>

      <Pricing text="Pack commerces et restaurants" type="commerce" />
    </Layout>
  );
}

export default VisitePage;
