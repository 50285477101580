import React, { useState } from "react";
import PropTypes from "prop-types";


function Options({ type }) {
  const [isOptionOpen, togglePopinOption] = useState(false);
  return (
    <div>
      <p className="mt-4 text-sm leading-5">
        <button
          onClick={() => togglePopinOption(!isOptionOpen)}
          aria-label="voir les options"
          className="font-medium text-gray-500 underline"
        >
          Voir les options disponibles
        </button>
      </p>
      <div
        onClick={() => togglePopinOption(!isOptionOpen)}
        className={`${
          isOptionOpen
            ? `fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center`
            : `hidden`
        }`}
      >
        <div
          className={`${
            isOptionOpen ? `fixed inset-0 transition-opacity` : `hidden`
          }`}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
          className={`${
            isOptionOpen
              ? `bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-xl sm:w-full sm:p-6`
              : `hidden`
          }`}
        >
          <div>
            <div className="absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => togglePopinOption(!isOptionOpen)}
                type="button"
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Fermer la vidéo"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-5 text-center">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-red-600">
                  Options de la prestation
                </h4>
                <div className="flex-1 border-t-2 border-gray-200"></div>
              </div>
              <ul className="mt-8 text-base text-left">
                <li className="mt-4 flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-green-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p className="ml-3 leading-5 text-gray-700">
                    Vidéo visite guidée + musique{" "}
                    <span className="inline rounded-full bg-red-200 uppercase px-2 py-1 font-bold mr-3 text-gray-800">
                      +30€
                    </span>
                  </p>
                </li>
                <li className="mt-4 flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-green-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p className="ml-3 leading-5 text-gray-700">
                    Plan 2D schématique{" "}
                    <span className="inline rounded-full bg-red-200 uppercase px-2 py-1 font-bold mr-3 text-gray-800">
                      +30€
                    </span>
                  </p>
                </li>
                <li className="mt-4 flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-green-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p className="ml-3 leading-5 text-gray-700">
                    Pack photographies immobilières{" "}
                    <span className="inline rounded-full bg-red-200 uppercase px-2 py-1 font-bold mr-3 text-gray-800">
                      +40€
                    </span>
                  </p>
                </li>
                <li className="mt-4 flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-green-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p className="ml-3 leading-5 text-gray-700">
                    Maillage 3D et nuage de points (fichiers .obj et .xyz){" "}
                    <span className="inline rounded-full bg-red-200 uppercase px-2 py-1 font-bold mr-3 text-gray-800">
                      +80€
                    </span>
                  </p>
                </li>

                <li className="mt-4 flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-green-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p className="ml-3 leading-5 text-gray-700">
                    Extension hébergement{" "}
                    {type == "commerce" ? "1 an" : "6 mois"}{" "}
                    <span className="inline rounded-full bg-red-200 uppercase px-2 py-1 font-bold mr-3 text-gray-800">
                      {type == "commerce" ? "+45€" : "+25€"}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Options.defaultProps = {
  type: `immobilier`,
};

Options.propTypes = {
  type: PropTypes.string,
};

export default Options;

